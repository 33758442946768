import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

const Seo = ({ title, seoDesc }) => {
  const schemaOrg = {
    "@context": "http://schema.org",
    "@type": "SoftwareApplication",
    name: "ReCollect",
    image:
      "https://stupefied-snyder-88e5f0.netlify.app/img/RECOLLECT_APP_ICON.svg",
    url: "https://re-collect.com.au/",
    sameAs: [
      "https://twitter.com/ReCollectAU",
      "https://www.facebook.com/recollectau/",
      "https://www.linkedin.com/showcase/recollectaus/",
      "https://www.instagram.com/recollectau/",
    ],
    author: {
      "@type": "Person",
      name: "LessThan3 Inc.",
    },
    datePublished: "2019-12-09",
    publisher: {
      "@type": "Organization",
      name: "LessThan3 Inc.",
    },
    applicationCategory: "Utilities",
    downloadUrl: "https://app.re-collect.com.au/",
    operatingSystem: "Android, iOS, MacOS, Windows",
    screenshot:
      "https://stupefied-snyder-88e5f0.netlify.app/img/phone-recollect/phone-3.png",
    softwareVersion: "2.7.0",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.6",
      bestRating: "5",
      ratingCount: "163",
    },
    offers: {
      "@type": "Offer",
      price: "0",
      priceCurrency: "CAD",
    },
  }

  // useEffect(() => {
  //   // Load google tag manager script
  //   window.dataLayer = window.dataLayer || []
  //   function gtag() {
  //     window.dataLayer.push(arguments)
  //   }
  //   gtag("js", new Date())
  //   gtag("config", "AW-1007387135")
  // }, [])

  return (
    <Helmet>
      <html lang="en" />
      <meta charset="UTF-8" />
      <title>{title}</title>
      <meta name="description" content={seoDesc} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="https://re-collect.com.au/" />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://stupefied-snyder-88e5f0.netlify.app/recollect.jpg"
      />
      <meta property="og:description" content={seoDesc} />
      <meta property="og:site_name" content="ReCollect" />

      <meta
        name="facebook-domain-verification"
        content="hmx3dyjn5uq3cwinxig0z1q2erojs0"
      />

      {/* iOS Smart App Banner */}
      {/* <meta name="apple-itunes-app" content="app-id=1359990960" /> */}

      {/* Favicon Stuff */}
      <meta name="msapplication-TileColor" content="#454545" />
      <meta name="theme-color" content="#454545" />
      <meta
        name="google-site-verification"
        content="saGRQEBn_hWBdOeiz6ZPZxweaR0oNoyYWiIA31DpV30"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon-recollect.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-recollect-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-recollect-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#8cc949" />
      <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
      {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-V0B9W8XVRS"></script> */}
    </Helmet>
  )
}

export default Seo
