import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import { LOCATIONS, PROVINCES } from "../constants"
import ReCollectLogo from "../../static/img/svg/recollect-logo-with-text.svg"

const Wrapper = styled.nav`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  width: 100%;
  padding: 0 3rem;
  transition: all 0.3s;
  transform: translate(-50%, 0);
`
const Container = styled.div`
  max-width: 130rem;
  margin: 0 auto;
  height: 8.5rem;
  display: flex;
  align-items: center;
  color: #fafafa;
  position: relative;
  @media only screen and (max-width: 68.75em) {
    justify-content: space-between;
  }
  @media only screen and (max-width: 52em) {
    padding: 0;
  }
`

const Checkbox = styled.input`
  display: none;
`

const MenuIcon = styled.label`
  position: relative;
  display: none;
  z-index: 10;
  height: 5rem;
  width: 5rem;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
    height: 2px;
    background-color: #efefef;
    transition: all 0.3s;
    ${Checkbox}:checked ~ & {
      background-color: transparent;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 3rem;
      height: 2px;
      background-color: #efefef;
      transition: all 0.3s;
    }
    &::before {
      top: 0.75rem;
      ${Checkbox}:checked ~ & {
        transform: rotate(135deg);
        top: 0;
        background-color: #505050;
      }
    }
    &::after {
      top: -0.75rem;
      ${Checkbox}:checked ~ & {
        transform: rotate(-135deg);
        top: 0;
        background-color: #505050;
      }
    }
  }
  @media only screen and (max-width: 68.75em) {
    display: block;
  }
`

const Links = styled.div`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-transform: uppercase;
  font-weight: 700;
  @media only screen and (max-width: 68.75em) {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 3rem;
    padding: 3rem;
    z-index: 9;
    position: absolute;
    top: 1.5rem;
    right: 0.5rem;
    background-color: #fafafa;
    opacity: 0;
    border-radius: 10px;
    visibility: hidden;
    transition: all 0.3s;
    width: 350px;
    box-shadow: 0 2px 6px rgba(0, 21, 64, 0.25);
    @media only screen and (max-width: 40em) {
      right: 50%;
      width: 100%;
      transform: translateX(50%);
    }
    ${Checkbox}:checked ~ & {
      opacity: 1;
      visibility: visible;
      @media only screen and (max-width: 40em) {
        transform: translateX(50%);
      }
    }
  }
  @media only screen and (max-width: 40em) {
    justify-items: initial;
  }
`

const NavLink = styled(Link, {
  shouldForwardProp: (prop) => !prop.startsWith("$"),
})`
  text-decoration: none;
  position: relative;
  z-index: 2;
  white-space: nowrap;
  &::after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 0;
    display: block;
    height: 1px;
    width: 0;
    background-color: ${(props) => (props.$invert ? "#505050" : "#fafafa")};
    transition: all 0.2s;
  }
  &:link,
  &:visited {
    margin: 0 1rem;
    color: ${(props) => (props.$invert ? "#505050" : "#fafafa")};
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
  }
  &:hover,
  &:active {
    &::after {
      width: 100%;
      left: 0;
    }
  }
  @media only screen and (max-width: 68.75em) {
    &:link,
    &:visited {
      font-size: 1.6rem;
      color: #505050;
    }
  }
`

const DropdownNavLinks = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const DropdownWrapper = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;
  position: absolute;
  left: 50%;
  top: 0;
  padding-top: 4rem;
  transform: translate(-50%, 0);
  @media only screen and (min-width: 68.75em) {
    &:hover,
    ${DropdownNavLinks}:hover & {
      visibility: visible;
      opacity: 1;
    }
  }
`

const AdditionalNavLinks = styled.div`
  position: relative;
  background-color: #fafafa;
  border-radius: 10px;
  padding: 1.5rem 2rem;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 1rem 1.5rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  &::after {
    content: "";
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    top: 0;
    left: 50%;
    background-color: #fafafa;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  ${NavLink} {
    margin: 1rem 0;
  }
`

const StateHeader = styled.div`
  text-align: center;
  color: #505050;
  grid-column: 1 / -1;
  padding: 0.5rem 0;
`

const AdditionalNavButtons = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
`

const AdditionalNavButton = styled.button`
  position: relative;
  display: block;
  border: none;
  cursor: pointer;
  font-weight: 700;
  background: #fafafa;
  color: #505050;
  font-size: 2rem;
  padding: 0 1rem;
  text-decoration: ${(props) => (props.active ? "underline" : "none")};
  outline: none;
  :not(:last-of-type) {
    &::after {
      content: "";
      position: absolute;
      right: -1px;
      height: 100%;
      width: 2px;
      background-color: #505050;
    }
  }
`

const NavSection = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 68.75em) {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 3rem;
  }
`

const LoginButton = styled.a`
  position: relative;
  color: black;
  font-size: 1.5rem;
  font-weight: 700;
  margin-left: auto;
  text-decoration: none;
  text-transform: uppercase;
  color: #fafafa;
  margin: 0 1rem;
  white-space: nowrap;
  &::after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 0;
    display: block;
    height: 1px;
    width: 0;
    background-color: #fafafa;
    transition: all 0.2s;
  }
  &:hover {
    &::after {
      width: 100%;
      left: 0;
    }
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 5px;
    display: inline-block;
    height: 2px;
    width: 1.5rem;
    background-color: currentcolor;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      height: 2px;
      width: 7.5px;
      background-color: currentcolor;
      transform-origin: right;
    }
    &::before {
      transform: translateY(-50%) rotate(45deg);
    }
    &::after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }
  @media only screen and (max-width: 68.75em) {
    font-size: 1.6rem;
    width: 100%;
    color: #505050;
  }
  @media only screen and (max-width: 40em) {
    text-align: left;
  }
`

const Marker = styled.span`
  display: block;
  height: 4rem;
  width: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  @media only screen and (max-width: 68.75em) {
    width: 100%;
    height: 2px;
    background-color: rgba(50, 50, 50, 0.3);
  }
`

const Nav = ({ loc }) => {
  const [province, setProvince] = useState()

  const handleChangeProvince = (name) => () => {
    setProvince(name)
  }

  useEffect(() => {
    if (loc && loc.province) {
      setProvince(loc.province)
    }
  }, [loc])

  function toSentenceCase(string) {
    if (string) {
      const text = string.replace(/([A-Z])/g, " $1")
      return text.charAt(0).toUpperCase() + text.slice(1)
    }
  }

  function generateLocationPostRelativeUrl(city) {
    let urlPrefix = "recycling"
    if (province === "queensland" || province === "westernAustralia") {
      urlPrefix = "containers-for-change"
    } else if (province === "australianCapitalTerritory") {
      urlPrefix = "return-it"
    } else if (province === "newSouthWales") {
      urlPrefix = "return-and-earn"
    }

    return `${urlPrefix}-${city.replace(/ \& |\s|, /g, "-").toLowerCase()}`
  }

  const svgStyles = {
    fill: "#fafafa",
    cursor: "pointer",
    marginRight: "2rem",
    height: "4.5rem",
    width: "auto",
  }

  return (
    <Wrapper>
      <Container>
        <Link to="/">
          <ReCollectLogo style={svgStyles} />
        </Link>
        <Checkbox type="checkbox" id="menu" />
        <MenuIcon htmlFor="menu">
          <span />
        </MenuIcon>
        <Links>
          <NavSection>
            <DropdownNavLinks>
              <NavLink to="/recycling">Recycling</NavLink>
              <DropdownWrapper>
                <AdditionalNavLinks>
                  <NavLink to="/recycling/beverages" $invert>
                    Beverages
                  </NavLink>
                  <NavLink to="/recycling/cellphones" $invert>
                    Cell Phones
                  </NavLink>
                  {/* <NavLink to="/recycling/electronics" $invert>
                    Electronics
                  </NavLink> */}
                </AdditionalNavLinks>
              </DropdownWrapper>
            </DropdownNavLinks>
            <DropdownNavLinks>
              <NavLink to="/services">Services</NavLink>
              <DropdownWrapper>
                <AdditionalNavLinks>
                  <NavLink to="/services/residential-recycling" $invert>
                    Residential
                  </NavLink>
                  <NavLink to="/services/commercial-recycling" $invert>
                    Commercial
                  </NavLink>
                  <NavLink to="/services/condo-recycling" $invert>
                    Unit
                  </NavLink>
                  <NavLink to="/services/bottle-drives" $invert>
                    Bottle Drives
                  </NavLink>
                  <NavLink to="/services/company-bottle-drives" $invert>
                    Company Bottle Drives
                  </NavLink>
                  {/* <NavLink to="/services/drop-and-go" $invert>
                    Drop&Go
                  </NavLink> */}
                </AdditionalNavLinks>
              </DropdownWrapper>
            </DropdownNavLinks>
            <DropdownNavLinks>
              <NavLink to="/fundraising">Fundraising</NavLink>
              <DropdownWrapper>
                <AdditionalNavLinks>
                  <NavLink to="/fundraising/charity" $invert>
                    Charity
                  </NavLink>
                  <NavLink to="/fundraising/not4profit" $invert>
                    Not-4-Profit
                  </NavLink>
                  <NavLink to="/fundraising/school" $invert>
                    School
                  </NavLink>
                  <NavLink to="/fundraising/club" $invert>
                    Club
                  </NavLink>
                  <NavLink to="/fundraising/league" $invert>
                    League
                  </NavLink>
                  <NavLink to="/fundraising/community" $invert>
                    Community
                  </NavLink>
                  <NavLink to="/fundraising/church" $invert>
                    Church
                  </NavLink>
                  <NavLink to="/fundraising/personal" $invert>
                    Personal
                  </NavLink>
                </AdditionalNavLinks>
              </DropdownWrapper>
            </DropdownNavLinks>
            <DropdownNavLinks>
              <NavLink to="/locations">Locations</NavLink>
              <DropdownWrapper>
                <AdditionalNavLinks>
                  <AdditionalNavButtons>
                    {Object.keys(PROVINCES).map((p, idx) => {
                      return (
                        <AdditionalNavButton
                          key={idx}
                          onClick={handleChangeProvince(p)}
                          active={p === province}
                        >
                          {PROVINCES[p]}
                        </AdditionalNavButton>
                      )
                    })}
                  </AdditionalNavButtons>
                  <StateHeader>{toSentenceCase(province)}</StateHeader>
                  {LOCATIONS[province] &&
                    LOCATIONS[province].map((city, count) => {
                      return (
                        <NavLink
                          to={`/locations/${generateLocationPostRelativeUrl(
                            city
                          )}`}
                          key={count}
                          $invert
                        >
                          {city}
                        </NavLink>
                      )
                    })}
                </AdditionalNavLinks>
              </DropdownWrapper>
            </DropdownNavLinks>
            <NavLink to="/pricing">Pricing</NavLink>
            <NavLink to="/faq">FAQ</NavLink>
            <NavLink to="/blog">Blog</NavLink>
          </NavSection>
          <NavSection>
            <NavLink to="/contact">Contact</NavLink>
            <Marker />
            <LoginButton href="https://app.re-collect.com.au/login">
              Log in
            </LoginButton>
            <LoginButton href="https://app.re-collect.com.au/register">
              Sign up
              <span />
            </LoginButton>
          </NavSection>
        </Links>
      </Container>
    </Wrapper>
  )
}

export default Nav
