import remark from "remark"
import remarkHtml from "remark-html"

export const createHTML = (data) => ({
  __html: remark().use(remarkHtml).processSync(data).toString(),
})

export const getContrastText = (hex) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (!result) {
    return "#FFFFFF"
  }

  let red = parseInt(result[1], 16)
  let green = parseInt(result[2], 16)
  let blue = parseInt(result[3], 16)

  if (red * 0.299 + green * 0.587 + blue * 0.114 > 186) {
    return "#000000"
  } else {
    return "#FFFFFF"
  }
}
