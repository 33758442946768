import React, { useContext } from "react"
import styled from "@emotion/styled"
import { EmailContext } from "../contexts/EmailContext"
import LocationSelect from "./LocationSelect"
import Mail from "../../static/img/svg/mail.svg"
import Phone from "../../static/img/svg/phone.svg"
import { Link, graphql, useStaticQuery } from "gatsby"
import { getContrastText } from "../utils"

const Container = styled.section`
  background: #202020;
  position: sticky;
  line-height: 1.3;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
`

const Content = styled.div`
  max-width: 130rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 40em) {
    grid-column-gap: 1.5rem;
  }
  @media only screen and (max-width: 32.5em) {
    grid-column-gap: 1rem;
  }
  @media only screen and (max-width: 31.5em) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 1rem;
  }
`

const Details = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  @media only screen and (max-width: 52em) {
    flex-direction: column;
  }
  @media only screen and (max-width: 31.5em) {
    :nth-of-type(2) {
      grid-row: 1 / 3;
      grid-column: 2 / 3;
    }
  }
`

const List = styled.ul`
  list-style: none;
`

const Secondary = styled.h4`
  font-size: 1.8rem;
  font-weight: 400;
  color: #fafafa;
  padding-right: 1.5rem;
  @media only screen and (max-width: 52em) {
    padding-right: 0;
  }
  @media only screen and (max-width: 40em) {
    display: none;
  }
`

const Item = styled.li`
  font-size: 1.5rem;
  color: #fafafa;
  display: flex;
  align-items: center;
  svg {
    fill: #fafafa;
    height: 1.5rem;
    padding-right: 1rem;
  }
`

const BadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 52em) {
    padding-top: 1rem;
  }
  @media only screen and (max-width: 40em) {
    padding-top: 0;
  }
  @media only screen and (max-width: 31.5em) {
    flex-direction: column;
  }
`

const BadgeLink = styled.a`
  margin: 0 1rem;
  display: flex;
  align-items: center;
  height: 35.2px;
  @media only screen and (max-width: 31.5em) {
    margin: 0;
    :not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`

const CloseButton = styled.span`
  position: absolute;
  top: ${(props) => (props.hasPromo ? "25%" : "50%")};
  right: 1rem;
  transform: translateY(-50%);
  display: block;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 2rem;
    background: #fafafa;
    top: 50%;
    left: 50%;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  @media only screen and (max-width: 40em) {
    top: 3rem;
  }
  @media only screen and (max-width: 31.5em) {
    top: 2rem;
    right: 1rem;
  }
`

const ExternalLink = styled.a`
  color: #fafafa;
  text-decoration: none;
  @media only screen and (max-width: 52em) {
    font-size: 1.6rem;
    font-weight: 400;
  }
`

const InternalLink = styled.span`
  color: #fafafa;
  cursor: pointer;
  @media only screen and (max-width: 52em) {
    font-size: 1.6rem;
    font-weight: 400;
  }
`

const Picture = styled.picture`
  height: 36px;
  @media only screen and (max-width: 31.5em) {
    height: auto;
    width: 119px;
    img {
      height: auto;
      width: 100%;
    }
  }
  img {
    height: 100%;
  }
`

const Promo = styled.div`
  text-align: center;
  color: ${({ color }) => (color ? color : "white")};
  max-width: 130rem;
  margin: 0 auto;
`

const ContainerChild = styled.div`
  padding: 1rem 3rem;

  :nth-of-type(2) {
    background-color: ${({ bgColor }) => (bgColor ? bgColor : "#35abe2")};
  }

  @media only screen and (max-width: 40em) {
    padding: 1rem 2.5rem 1rem 1.5rem;
  }
  @media only screen and (max-width: 31.5em) {
    padding: 1rem 1.5rem;
  }
`

const StyledLink = styled(Link)`
  color: #ffd24d;
  cursor: pointer;
  text-decoration: underline;
`

const PromoCode = styled.strong`
  color: #ffd24d;
`

const CtaToast = ({ setCta }) => {
  const { setShowEmail } = useContext(EmailContext)

  const data = useStaticQuery(graphql`
    query ToastQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          promoBannerSettings {
            enabled
            bgColor
            description
            url
            linkText
          }
        }
      }
    }
  `)

  const { enabled, bgColor, description, url, linkText } =
    data.markdownRemark.frontmatter.promoBannerSettings || {}

  return (
    <Container>
      <ContainerChild>
        <Content>
          <Details>
            <List>
              <Item>
                <Phone />
                <ExternalLink href="tel:0262808538">Call us</ExternalLink>
              </Item>
              <Item>
                <Mail />
                <InternalLink onClick={() => setShowEmail(true)}>
                  Email us
                </InternalLink>
              </Item>
            </List>
          </Details>
          <Details>
            <Secondary>Get the app!</Secondary>
            <BadgeWrapper>
              <BadgeLink href="https://apps.apple.com/au/app/return-it-collect/id1474675831">
                <Picture>
                  <source
                    srcSet="/img/app-store-badge-50px.webp"
                    type="image/webp"
                  />
                  <img
                    src="/img/app-store-badge-50px.png"
                    alt="Download on the App Store"
                    loading="lazy"
                  />
                </Picture>
              </BadgeLink>
              <BadgeLink href="https://play.google.com/store/apps/details?id=au.com.returnit">
                <Picture>
                  <source
                    srcSet="/img/google-play-badge-50px.webp"
                    type="image/webp"
                  />
                  <img
                    src="/img/google-play-badge-50px.png"
                    alt="Get it on Google Play"
                    loading="lazy"
                  />
                </Picture>
              </BadgeLink>
            </BadgeWrapper>
          </Details>
          <Details>
            <Secondary>Not your city?</Secondary>
            <LocationSelect />
          </Details>
        </Content>
        <CloseButton onClick={() => setCta(false)} hasPromo={enabled} />
      </ContainerChild>
      {enabled && (
        <ContainerChild bgColor={bgColor}>
          <Promo color={getContrastText(bgColor)}>
            {description}{" "}
            <StyledLink to={url} target="_blank">
              <strong>{linkText}</strong>
            </StyledLink>
          </Promo>
        </ContainerChild>
      )}
    </Container>
  )
}

export default CtaToast
