import React from "react"
import styled from "@emotion/styled"

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const ExternalLink = styled.a`
  margin: 0 1.5rem;
`

const Svg = styled.img``

const SocialMedia = ({ height, textcolor }) => (
  <Container>
    <ExternalLink href="https://twitter.com/ReCollectAU" target="__blank">
      <Svg
        src="/img/svg/twitter.svg"
        alt="Twitter logo"
        height={height}
        width={height}
      />
    </ExternalLink>
    <ExternalLink
      href="https://www.instagram.com/recollectau/"
      target="__blank"
    >
      <Svg
        src="/img/svg/instagram.svg"
        alt="Instagram logo"
        height={height}
        width={height}
      />
    </ExternalLink>
    <ExternalLink href="https://www.facebook.com/recollectau/" target="__blank">
      <Svg
        src="/img/svg/facebook.svg"
        alt="Facebook logo"
        height={height}
        width={height}
      />
    </ExternalLink>
    <ExternalLink
      href="https://www.linkedin.com/showcase/recollectaus/"
      target="__blank"
    >
      <Svg
        src="/img/svg/linkedin.svg"
        alt="LinkedIn logo"
        height={height}
        width={height}
      />
    </ExternalLink>
  </Container>
)

export default SocialMedia
